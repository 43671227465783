<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehicleVideosModal' hide-footer>
        <addVehicleVideos @closeAddVehicleVideos='toggleAddVehicleVideos()' @addVehicleVideosSuccess='addVehicleVideosSuccess()'></addVehicleVideos>
      </b-modal>
      <b-modal ref='editVehicleVideosModal' hide-footer>
        <editVehicleVideos
          :editingVehicleVideos='currentVehicleVideos'
          @closeEditVehicleVideos='toggleEditVehicleVideos()'
          @editVehicleVideosSuccess='editVehicleVideosSuccess()'
        ></editVehicleVideos>
      </b-modal>
      <div class='row'>
        <h2>VehicleVideos</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicleVideos()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleVideos from '../../components/addVehicleVideos';
import editVehicleVideos from '../../components/editVehicleVideos';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'VehicleId',
                field: 'VehicleId',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'VideoId',
                field: 'VideoId',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addVehicleVideosOpen: false,
      editVehicleVideosOpen: false,
      currentVehicleVideos: {}
    };
  },
  components: {
    addVehicleVideos,
    editVehicleVideos,
    Datatable,
  },
  created: function() {
    this.getVehicleVideos();
    this.addVehicleVideosOpen = false;
    this.editVehicleVideosOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getVehicleVideos() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicleVideos', payload);
    },
    editItem(VehicleVideos) {
      this.toggleEditVehicleVideos();
      this.currentVehicleVideos = VehicleVideos;
    },
    toggleAddVehicleVideos() {
      if(this.addVehicleVideosOpen)
      {
        this.$refs.addVehicleVideosModal.hide()
      }
      else{
        this.$refs.addVehicleVideosModal.show()
      }
      this.addVehicleVideosOpen = !this.addVehicleVideosOpen;
    },
    addVehicleVideosSuccess() {
      this.toggleAddVehicleVideos();
      miniToastr['success']('VehicleVideos Added', 'Success', 1000, null);
      this.getVehicleVideos();
    },
    toggleEditVehicleVideos() {
      if(this.editVehicleVideosOpen)
      {
        this.$refs.editVehicleVideosModal.hide()
      }
      else{
        this.$refs.editVehicleVideosModal.show()
      }
      this.editVehicleVideosOpen = !this.editVehicleVideosOpen;
    },
    editVehicleVideosSuccess() {
      this.toggleEditVehicleVideos();
      miniToastr['success']('VehicleVideos Edited', 'Success', 1000, null);
      this.getVehicleVideos();
    }
  }
};
</script>
<style scoped>
.VehicleVideosThumbnail {
  height: 50px;
}
</style>
