<template>
  <div class='container'>
  <h2>Add A VehicleVideos</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='VehicleId'>VehicleId</label><input class=' form-control'   
                id='VehicleId' name='VehicleId' v-model='newVehicleVideos.VehicleId' placeholder='VehicleId' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Heading'>Heading</label><input class=' form-control'   
                id='Heading' name='Heading' v-model='newVehicleVideos.Heading' placeholder='Heading' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
<div class='col-md-4'>
    <label class for='Image'>Image</label>
    <div v-if='!editingImage'>
      <img v-bind:src='getImageSource(newVehicleVideos)' class='previewImage'>
      <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
    </div>
    <div v-if='editingImage'>
      <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
      <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
      <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl'/>
    </div>
  </div>
            <div class=' col-md-4'><label class='' for='VideoId'>VideoId</label><input class=' form-control'   
                id='VideoId' name='VideoId' v-model='newVehicleVideos.VideoId' placeholder='VideoId' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newVehicleVideos.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addVehicleVideos',
  created: function() {
  },
  data() {
      return {
          newVehicleVideos: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddVehicleVideos')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newVehicleVideos,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addVehicleVideosSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addVehicleVideos', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
